<template>
  <div id="container">
    <div id="domainCheck">
      <h2>线路域名检测</h2>
      <h4 style="color:grey;">自动检验线路连通性，如遇到某个线路不可用，请切换到其它线路使用</h4>
      <h5 style="color:grey;">
        如果遇到上传下载慢的情况，可以切换不同线路尝试。超大文件的上传下载，可以联系我们进行网盘传输。</h5>
      <DomainCheck/>
      <el-button type="primary" @click="gotoUserCenter">开始科研之旅</el-button>
    </div>
    <div>
      <BanClientIp/>
    </div>
  </div>
</template>

<script>
import DomainCheck from "@/components/DomainCheck";
import BanClientIp from "@/components/BanClientIp";

export default {
  name: "StatusCheck",
  components: {DomainCheck, BanClientIp},
  data() {
    return {}
  },
  methods: {
    refresh() {
      this.reload();
    },
    gotoUserCenter() {
      this.$router.push({
        path: '/boarding',
      })
    }
  }
}
</script>

<style scoped lang="less">
@import "@/assets/style/common.less";

#container {
  display: flex;
  flex-direction: column;
  min-height: 512px;

  #domainCheck {
    width: 100%;
    .card-item();
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
